import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"
import { InformativeStyles as I } from "./Styles";

import userService, { getUserId } from "../../services/user.service";
import { Informative, InformativeProps } from "./Informative";
import "../../v4.css"

export const AccountReady: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    const informativeProps: InformativeProps = {
        title: "Your account is ready",
        icon: icons.star2,
        desc:
            <I.InformativeDescText>
                Now save your personalized plan by setting up an
                account
            </I.InformativeDescText>
        ,
        background: "#F5A0D2",
        buttonText: "NEXT",
        onClick: () => {
            onClick("accountReady")
        }
    }

    return (
        <Informative { ...informativeProps }/>
    );
}

