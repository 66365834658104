import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"

import userService, { getUserId } from "../../services/user.service";
import { HeaderStyles as H, PageStyles as P, } from "./Styles";
import Header from "./Header";
import Options from "./Options";

import "../../v4.css"

export const TargetLanguage: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        window.sessionStorage.setItem("targetLanguage", value);
        handleContinue(value)
    }

    const langArray = [
        {
            "icon": icons.englishIcon,
            "text": "English",
            "popular": true
        },
        {
            "icon": icons.spanishIcon,
            "text": "Spanish"
        },
        {
            "icon": icons.arabicIcon,
            "text": "Arabic"
        },
        {
            "icon": icons.chineseIcon,
            "text": "Chinese"
        },
        {
            "icon": icons.frenchIcon,
            "text": "French"
        },
        {
            "icon": icons.germanIcon,
            "text": "German"
        },
        {
            "icon": icons.italianIcon,
            "text": "Italian"
        },
        {
            "icon": icons.koreanIcon,
            "text": "Korean"
        },
        {
            "icon": icons.ukrainianIcon,
            "text": "Ukranian"
        }
    ]

    return (
        <P.PageContainer>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <Header title={ "I want to learn:" } toolbarPercentage={ "16%" } toolbarText={ "1/7" }/>
            <P.QuestionContainer>
                <Options options={ langArray } onClick={ onClick }/>
            </P.QuestionContainer>
        </P.PageContainer>
    );
}

