import React, { useState } from "react";
import { OptionStyles as O } from "./Styles";
import icons from "./images"

export interface Option {
    icon: string,
    text: string,
    popular?: boolean
}

export interface OptionsProps {
    options: Option[]
    onClick?: any
}

export default function Options(props: OptionsProps) {
    const [answer, setAnswer] = useState("");

    return (
        <O.OptionsContainer>
            { props.options.map((prop) => (
                <O.OptionContainer selected={ prop.text === answer } onClick={ () => {
                    setAnswer(prop.text)
                    props.onClick && setTimeout(() => props.onClick(prop.text), 400);
                } }>
                    <O.IconContainer icon={ prop.icon }/>
                    <O.TextContainer>
                        { prop.text }
                    </O.TextContainer>

                    { prop.popular &&
                        <O.PopularContainer>
                            <O.PopularText>
                                POPULAR
                            </O.PopularText>
                        </O.PopularContainer>
                    }

                    { prop.text === answer &&
                        <O.SelectedContainer icon={ icons.selectedIcon }/>
                    }
                </O.OptionContainer>
            ))
            }
        </O.OptionsContainer>
    )
}
