import React from 'react';
import { RecommendedStyles as R } from "./Styles";


export const Recommended = (props: any) => {

    return (
        <R.Container>
            <R.TitleContainer>
                <R.Title>
                    Recommended
                </R.Title>
            </R.TitleContainer>
            <R.DescContainer>
                <R.WordContainer>
                    <R.Text>
                        Number of words to learn weekly:
                    </R.Text>
                    <br/>
                    <br/>
                    <R.Text3>18</R.Text3>
                    &nbsp;
                    &nbsp;
                    <R.Text1>Words</R.Text1>
                </R.WordContainer>
                <R.TopicContainer>
                    <R.Text>
                        Number of grammar topics covered by the program:
                    </R.Text>
                    <br/>
                    <br/>
                    <R.Text3>10</R.Text3>
                    &nbsp;
                    &nbsp;
                    <R.Text1>Topics</R.Text1>
                </R.TopicContainer>
            </R.DescContainer>
        </R.Container>
    );
}

