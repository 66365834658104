import React, { useEffect, useState } from 'react';
import { PlansStyles as P } from "./Styles";
import { ProgressButton } from "./Button";
import { GetPrices } from "../../api/dto";
import { configId } from "../../config";
import stripeService from "../../services/stripe.service";
import { captureException } from "../../services/sentry.service";
import { toast } from "react-toastify";
import { useGetPageInfo } from "../../hooks/useGetPageInfo";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type StripeRecurring = {
    aggregate_usage: string
    interval: string
    interval_count: number
    trial_period_days: number
    usage_type: string
}

type StripePrice = {
    id: string
    active: boolean
    currency: string
    unit_amount: number
    unit_amount_decimal: string
    recurring: StripeRecurring
};

export const Plans = (props: any) => {
    const { currentCohort } = useGetPageInfo();
    const [plans, setPlans] = useState(Array<StripePrice>)
    const [selectedPlan, setSelectedPlan] = useState<StripePrice>()
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const request: GetPrices = {
            configId: configId,
            cohortId: currentCohort
        }

        stripeService.getPrices(request).then(plans => setPlans(plans))
            .catch(err => {
                captureException(err);
                toast('Please try again later.')
            })
    }, []);

    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    let weekMap = new Map<string, number>([
        ["day", 0.7],
        ["week", 1],
        ["month", 4.3], // 4.3 = 52/12
        ["year", 52],
    ]);

    function getWeekCount(recurring: string): number {
        const weekCount = weekMap.get(recurring)
        if (weekCount) {
            return weekCount
        }
        return 1;
    }

    let actives = plans.filter(i => i.active);
    let weeklyPrices = actives.sort((a, b) => {
        const weeklyA = a.unit_amount / (a.recurring.interval_count * getWeekCount(a.recurring.interval))
        const weeklyB = b.unit_amount / (b.recurring.interval_count * getWeekCount(b.recurring.interval))
        return weeklyA - weeklyB
    });
    let bestPrice = weeklyPrices[0]; // cheapest weekly
    useEffect(() => {
        setSelectedPlan(bestPrice)
    }, [bestPrice])


    return (
        <P.Container>
            <P.PlansContainer>
                { plans.filter(plan => plan.active).map((plan) => (
                    <P.PlanContainer onClick={ () => {
                        selectedPlan?.id === plan.id ? setSelectedPlan(undefined) : setSelectedPlan(plan)
                    } }>
                        <P.DetailsContainer>
                            { bestPrice.id === plan.id && (
                                <P.Wrapper>
                                    <P.BestOfferContainer>
                                        <P.BestOfferText>🔥 BEST OFFER</P.BestOfferText>
                                    </P.BestOfferContainer>
                                </P.Wrapper>
                            ) }
                            <P.Text1>{ capitalizeFirstLetter(plan.recurring.interval) }ly</P.Text1>
                            <br/>
                            <P.Text>
                                { plan.recurring.interval_count } { plan.recurring.interval }
                            </P.Text>
                            &nbsp;
                            <P.Text2>
                                { getSymbolFromCurrency(plan.currency) }{ Math.round(plan.unit_amount / 100 * 100) / 100 }
                            </P.Text2>
                            <br/>
                            <P.Text2>
                                { getSymbolFromCurrency(plan.currency) }{ Math.round(plan.unit_amount / getWeekCount(plan.recurring.interval) / 100 * 100) / 100 } per
                                week
                            </P.Text2>
                        </P.DetailsContainer>
                        <P.SelectContainer selected={ selectedPlan?.id === plan.id }/>
                    </P.PlanContainer>
                ))
                }
            </P.PlansContainer>
            <ProgressButton text={ "BUY NOW" } disabled={ props.disabled } loading={ props.loading }
                            onclick={ () => {
                                if (!selectedPlan) {
                                    return
                                }

                                props.onClick && props.onClick(selectedPlan)
                            } }/>
            <br/>
            <P.TermsContainer>
                <P.TermsText>
                    <div>{ selectedPlan && t('plans.agreement', {
                        price: getSymbolFromCurrency(selectedPlan.currency) + '' + selectedPlan.unit_amount / 100,
                        duration: `every ${ selectedPlan.recurring.interval_count + ' ' + selectedPlan.recurring.interval }`
                    }) }</div>
                    <P.LinksContainer>
                        <span onClick={ () => navigate("/tos") }>Terms of service</span> { ' | ' }
                        <span onClick={ () => navigate("/pp") }>Privacy Policy</span>
                    </P.LinksContainer>
                </P.TermsText>
            </P.TermsContainer>
        </P.Container>
    );
}

