import React from 'react';
import { LearnFasterStyles as L } from "./Styles";
import icons from "./images"


export const LearnFaster = (props: any) => {

    return (
        <L.Container>
            <L.TitleContainer>
                <L.Title>
                    Learn Faster & Easier
                </L.Title>
                <br/>
                <L.Text>
                    Unlock Premium features and save $10 on your personal language study plan!
                </L.Text>
                <L.RocketContainerWrapper>
                    <L.RocketContainer img={ icons.rocket }/>
                </L.RocketContainerWrapper>
            </L.TitleContainer>
            <L.RegularPremiumContainer img={ icons.regularpremium }/>
            <L.DescContainer>
                <L.RegularContainer>
                    <L.ul>
                        <L.li><L.BulletText>Includes ads</L.BulletText></L.li>
                        <L.li><L.BulletText>Only 1 language</L.BulletText></L.li>
                        <L.li><L.BulletText>Limited lessons</L.BulletText></L.li>
                    </L.ul>
                </L.RegularContainer>
                <L.PremiumContainer>
                    <L.ul>
                        <L.li><L.BulletText>Premium Content</L.BulletText></L.li>
                        <L.li><L.BulletText>Personal Study plan</L.BulletText></L.li>
                        <L.li><L.BulletText>Exclusive courses</L.BulletText></L.li>
                        <L.li><L.BulletText>Practice quizzes</L.BulletText></L.li>
                        <L.li><L.BulletText>Ad-free</L.BulletText></L.li>
                    </L.ul>
                </L.PremiumContainer>
            </L.DescContainer>
        </L.Container>
    );
}

