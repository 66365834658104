import styled from 'styled-components'
import icons from "./images";

export const PageStyles = {
    PageContainer: styled.div`{
      height: 100%;
      padding-bottom: 20px;
      padding-right: 10px;
      padding-left: 10px;
    }`,

    QuestionContainer: styled.div`{

    }`,

    Overlay: styled.div`{
      background: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
    }`
}

export const HeaderStyles = {
    HeaderContainer: styled.div`{
      background: #F3FAFF;
      width: 100vw;
      position: relative;
      left: calc(-50vw + 50%);
      z-index: 11;
    }`,

    IconContainer: styled.div<{ icon: string }>`{
      width: 198px;
      height: 57px;
      background: transparent url(${ props => props.icon }) 0% 50% no-repeat padding-box;
      padding: 40px;
      margin-left: 50px
    }`,
}

export const ToolbarStyles = {
    ToolbarTitleContainer: styled.div`{
    }`,

    ToolbarContainer: styled.div`{
      display: flex;
      flex-direction: row;
      align-items: center;
    }`,

    ToolbarButton: styled.div<{ icon: string }>`{
      width: 50px;
      height: 50px;
      background: transparent url(${ props => props.icon }) 50% 50% no-repeat padding-box;
      margin: 10px;
    }`,

    ProgressBarContainer: styled.div`{
      width: 70%;
      border-radius: 16px;
      height: 50px;
      opacity: 1;
      background: #C5F8F5 0% 0% no-repeat padding-box;
    }`,

    ProgressBarFiller: styled.div<{ completed: string }>`{
      height: 100%;
      width: ${ props => props.completed };
      border-radius: 16px;
      opacity: 1;
      background: transparent linear-gradient(90deg, #92A2EB 30%, #A1EAED 100%);
      display: flex;
      align-items: center;
    }`,

    ProgressText: styled.span`{
      width: 100%;
      font-family: NunitoRegular;
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 0.15em;
      text-align: right;
    }`
}

export const TitleStyles = {
    TitleContainer: styled.div`{
      background: #BEC8FF;
      width: 100%;
      height: 123px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 10px;
      margin-bottom: 20px;
      margin-top: 10px
    `,

    TitleText: styled.p`{
      font-family: NunitoBold;
      font-size: 28px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
      margin: 10px;
    }`
}

export const OptionStyles = {

    IconContainer: styled.div<{ icon: string }>`{
      width: 50px;
      height: 50px;
      background: transparent url(${ props => props.icon }) 50% 50% no-repeat padding-box;
    }`,

    TextContainer: styled.div`{
      font-family: NunitoBold;
      font-size: 16px;
      font-weight: 2000;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      padding: 10px;
    }
    `,

    OptionsContainer: styled.div`{

    }`,

    OptionContainer: styled.div<{ selected: boolean }>`
      cursor: pointer;
      background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
      border: 1px solid ${ props => props.selected ? '#242424' : 'transparent' };
      border-radius: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
      width: 100%;
      height: 70px;
      padding: 10px;
    `,

    SelectedContainer: styled.div<{ icon: string }>`{
      width: 32px;
      height: 32px;
      background: transparent url(${ props => props.icon }) 0% 0% no-repeat padding-box;
      margin-left: auto;
      margin-right: 0;
    }`,

    PopularContainer: styled.div`{
      background: #FFF050;
      padding: 10px;
      width: 83px;
      height: 30px;
      text-align: center;
      display: flex;
      align-items: center;
      margin-left: 20px;
    }`,

    PopularText: styled.span`{
      font-family: NunitoRegular;
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: 0em;
    }`,

}

export const InformativeStyles = {
    InformativeContainer: styled.div<{ background: string }>`{
      background: ${ props => props.background };
      margin-top: 10px;
      border-radius: 16px;
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }`,

    InformativeTitleContainer: styled.div`{
      padding: 20px;
    }`,

    InformativeTitle: styled.p`{
      font-family: NunitoBold;
      font-size: 45px;
      font-weight: 800;
      line-height: 54px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    InformativeImage: styled.div<{ img: string }>`{
      background: transparent url(${ props => props.img }) 90% 0% no-repeat padding-box;
      height: 360px;
    }`,

    InformativeDescContainer: styled.div`{
      width: 60%;
      margin-left: 25px;
    }`,

    InformativeDescText: styled.p`{
      font-family: NunitoRegular;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    InformativeDescTextBold: styled.span`{
      font-family: NunitoBold;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
    }`
}

export const ButtonStyles = {
    ButtonContainer: styled.button<{ disabled: boolean }>`{
      border-radius: 16px;
      background: ${ props => props.disabled ? "#D1D1D1" : "#242424" } 50% 0% no-repeat padding-box;
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
    }`,

    ButtonText: styled.p`{
      font-family: NunitoRegular;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
      color: #FFFFFF;
    }`,

    TryButtonContainer: styled.div`{
      border-radius: 16px;
      background: #BCF0D0 0% 0% no-repeat padding-box;
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;

    }`,

    TryButtonText: styled.p`{
      font-family: NunitoRegular;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: center;
      color: #242424;
    }`,

    LoadingContainer: styled.div`
      text-align: center;
      display: flex;
      justify-content: center;
    `,

    Loading: styled.img`
    `
}

export const WhatIsYourNameStyles = {
    AnswerContainer: styled.div`{
      background: #FFFFFF;
      border-radius: 16px;
      padding: 30px;
      text-align: center;
      margin-bottom: 20px;
    }`,

    AnswerInput: styled.input`
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #DCDCDC;
      border-radius: 16px;
      height: 60px;
      width: 100%;
      opacity: 1;
      padding: 10px;
    `,

    AnswerLabelContainer: styled.div`{
      text-align: left;
      margin-bottom: 10px;
      margin-top: 10px;
    }`,

    AnswerLabel: styled.label`{
      font-family: Nunito;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    EyeWrapper: styled.div`{
      position: relative;
    }`,

    Eye: styled.span<{ toggle: boolean }>`{
      left: 330px;
      top: -40px;
      position: absolute;
      display: inline-block;
      background: url(${ props => props.toggle ? icons.eyeIcon : icons.eyeCloseIcon });
      width: 24px;
      height: 24px;
    }`,

    Warning: styled.img`{
      margin-right: 5px;
    }`,

    ErrorContainer: styled.div`{
      display: flex;
      flex-direction: row;
    }`,

    ErrorMessage: styled.p`{
      font-family: NunitoRegular;
      font-size: 14px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: #F91E1E;
    }`,
}


export const AnswerOverviewStyles = {
    Container: styled.div`{
      border-radius: 16px;
      background: #BEC8FF;
      margin-top: 10px;
      padding: 20px;
    }`,

    TitleContainer: styled.div`{

    }`,

    Title: styled.p`{
      font-family: NunitoBold;
      font-size: 48px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    AnswerContainer: styled.div`{
      margin-top: 20px;
    }`,

    AnswerInputContainer: styled.div`
      margin-top: 20px;
    `,

    AnswerInput: styled.label`
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #DCDCDC;
      border-radius: 16px;
      height: 60px;
      opacity: 1;
      padding: 10px;
      margin-right: 3px;
    `,
}

export const ForecastStyles = {
    Container: styled.div`{
      margin-top: 10px;
      border-radius: 16px;
      background: #FFFFFF;
      padding: 20px;
    }`,

    TitleContainer: styled.div`{

    }`,

    Title: styled.p`{
      font-family: NunitoBold;
      font-size: 48px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    ImageContainer: styled.div<{ img: string }>`{
      background: transparent url(${ props => props.img }) 0% 0% no-repeat padding-box;
      height: 204px;
      margin-top: 20px;
    }`
}

export const RecommendedStyles = {
    Container: styled.div`{
      margin-top: 10px;
      border-radius: 16px;
      background: #C8DF87;
      padding: 20px;
    }`,

    TitleContainer: styled.div`{

    }`,

    Title: styled.p`{
      font-family: NunitoBold;
      font-size: 48px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    DescContainer: styled.div`{
      display: flex;
      flex-direction: row;
    }`,

    WordContainer: styled.div`{
      padding: 10px;
      width: 50%;
    }`,

    TopicContainer: styled.div`{
      width: 50%;
      padding: 10px;
    }`,

    Text: styled.span`{
      font-family: NunitoRegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    Text1: styled.span`{
      font-family: NunitoBold;
      font-size: 24px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    Text2: styled.span`{
      font-family: NunitoBold;
      font-size: 56px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    Text3: styled.span`{
      font-family: NunitoBold;
      font-size: 48px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }`
}

export const LearnFasterStyles = {
    Container: styled.div`{
      margin-top: 10px;
      border-radius: 16px;
      background: #FFF050;
      padding: 20px;
    }`,

    TitleContainer: styled.div`{
    }`,


    Title: styled.p`{
      font-family: NunitoBold;
      font-size: 48px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    DescContainer: styled.div`{
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }`,

    RegularContainer: styled.div`{
      width: 50%;
    }`,

    PremiumContainer: styled.div`{
      width: 50%;
    }`,

    Text: styled.span`{
      font-family: NunitoRegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    BulletText: styled.span`{
      font-family: NunitoRegular;
      font-size: 16px;
      font-weight: 600;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    ul: styled.ul`{
    }`,

    li: styled.li`{
      list-style: disc outside none;
      display: list-item;
      margin-left: 1em;
    }`,

    RocketContainerWrapper: styled.div`{
      position: relative;
    }`,

    RocketContainer: styled.div<{ img: string }>`{
      position: absolute;
      width: 142px;
      height: 154px;
      top: -180px;
      left: 220px;

      background: transparent url(${ props => props.img }) 0% 0% no-repeat padding-box;
      margin-top: 20px;
    }`,

    RegularPremiumContainer: styled.div<{ img: string }>`{
      background: transparent url(${ props => props.img }) 0% 0% no-repeat padding-box;
      width: 340px;
      height: 56px;
      margin-top: 20px;
    }`,
}

export const PlansStyles = {
    Container: styled.div`{
      margin-top: 10px;
      border-radius: 16px;
      background: #BCF0D0;
      padding: 10px 20px;
    }`,

    PlansContainer: styled.div`{
      margin-bottom: 10px;
    }`,

    PlanContainer: styled.div`{
      padding: 10px 20px;
      border-radius: 16px;
      background: #FFFFFF;
      display: flex;
      flex-direction: row;
      margin-top: 10px;
    }`,

    DetailsContainer: styled.div`{
      width: 50%;
    }`,

    SelectContainer: styled.div<{ selected: boolean }>`{
      background: transparent url(${ props => props.selected ? icons.selectedIcon : icons.unselected }) 95% 50% no-repeat padding-box;
      width: 50%;
    }`,

    Text: styled.span`{
      font-family: NunitoRegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    Text1: styled.span`{
      font-family: NunitoBold;
      font-size: 24px;
      font-weight: 800;
      line-height: 51px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    Text2: styled.span`{
      font-family: NunitoBold;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    TermsContainer: styled.div`{

    }`,

    TermsText: styled.span`{
      font-family: NunitoRegular;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;
      color: #242424B2;
    }`,

    a: styled.a`{
    }`,

    Wrapper: styled.div`{
      position: relative;
    }`,

    BestOfferContainer: styled.div`{
      display: flex;
      position: absolute;
      background: #FFF050;
      border-radius: 8px;
      width: 118px;
      height: 27px;
      text-align: center;
      left: 80px;
      top: 12px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }`,

    BestOfferText: styled.span`{
      font-family: NunitoRegular;
      font-size: 12px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0em;
      width: 100%;
    }`,

    LinksContainer: styled.div`
      font-family: NunitoBold;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;

      margin-top: 5px;
      cursor: pointer;
    `,
}

export const CheckoutStyles = {
    Container: styled.div`{
      margin-top: -10px;
      margin-bottom: -20px;
      border-radius: 16px;
      background: #FFFFFF;
      padding: 10px 20px;
      position: relative;
      z-index: 12;
    }`,

    CloseButtonWrapper: styled.div`{
      position: relative;
    }`,

    CloseButton: styled.span`{
      font-size: 36px;
      position: absolute;
      width: 40px;
      height: 40px;
      top: -60px;
      left: 350px;
      padding: 10px;
      border-radius: 36px;
      gap: 10px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }`,

    BillingContainer: styled.div`{
      margin-top: 20px;
      border-radius: 16px;
      background: #BCF0D0;
      padding: 10px 20px;
    }`,

    LDetailsContainer: styled.div`{
      width: 50%;
      text-align: left;
    }`,

    RDetailsContainer: styled.div`{
      width: 50%;
      text-align: right;
    }`,

    BillingTop: styled.div`{
      display: flex;
      flex-direction: row;
    }`,

    Text1: styled.span`{
      font-family: NunitoRegular;
      font-size: 24px;
      font-weight: 700;
      line-height: 39px;
      letter-spacing: 0em;
    }`,

    Text2: styled.span`{
      font-family: NunitoRegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
    }`,

    hr: styled.hr`{

    }`,

    PaymentTitle: styled.div`{
      text-align: center;
    }`,

    PaymentContainer: styled.div`
      background: #EEEEEE 0% 0% no-repeat padding-box;
      border-radius: 16px;
      opacity: 1;
      margin-top: 10px;
      padding: 20px 10px;
    `,

    ElementsContainer: styled.div`
      margin: 20px 0px;
    `,

    SecurePaymentContainer: styled.div`
      margin: 20px 0px;
      background: #EEEEEE 0% 0% no-repeat padding-box;
      border-radius: 16px;
      opacity: 1;
      padding: 30px 40px;
      text-align: center;
    `,

    Text3: styled.span`{
      font-family: NunitoBold;
      font-size: 20px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: left;
    }`,

    TermsText: styled.span`{
      font-family: NunitoRegular;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: #242424B2;
    }`,

    VisaContainer: styled.div<{ img: string }>`
      background: transparent url(${ props => props.img }) 50% 50% no-repeat padding-box;
      width: 100%;
      height: 40px;
    `,

    VerifyContainer: styled.div<{ img: string }>`
      background: transparent url(${ props => props.img }) 0% 50% no-repeat padding-box;
      width: 100%;
    `,
}

export const PaymentSuccessStyles = {
    Container: styled.div`{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border-radius: 16px;
      margin-top: 20px;
      position: relative;
      z-index: 11;
    }`,

    IconContainer: styled.div`{
      margin-top: 10px;
      background: transparent url(${ icons.paymentSuccess }) 50% 100% no-repeat padding-box;
      background-size: 380px;
      width: 90%;
      height: 175px;
    }`,

    DescContainer: styled.div`{
      font-family: NunitoBold;
      font-size: 20px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      width: 80%;
      margin-top: 20px;
    }`,

    ButtonContainer: styled.div`{
      width: 90%;
      margin-top: 20px;
      margin-bottom: 20px;
    }`
}


export const PaymentFailedStyles = {
    Container: styled.div`{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      border-radius: 16px;
      margin-top: 20px;
      position: relative;
      z-index: 11;
    }`,

    IconContainer: styled.div`{
      margin-top: 10px;
      background: transparent url(${ icons.paymentFailed }) 50% 100% no-repeat padding-box;
      background-size: 380px;
      width: 90%;
      height: 175px;

    }`,

    DescContainer: styled.div`{
      font-family: NunitoBold;
      font-size: 20px;
      font-weight: 800;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      width: 80%;
      margin-top: 20px;
    }`,

    ButtonContainer: styled.div`{
      width: 90%;
      margin-top: 20px;
      margin-bottom: 20px;
    }`
}

