import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"

import userService, { getUserId } from "../../services/user.service";
import { HeaderStyles as H, PageStyles as P, } from "./Styles";
import Header from "./Header";
import MultiSelectOptions from "./MultiSelectOptions";

import "../../v4.css"

export const WhatAspects: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        window.sessionStorage.setItem("aspects", value);

        handleContinue(value)
    }

    const langArray = [
        {
            "icon": icons.spellingIcon,
            "text": "Spelling"
        },
        {
            "icon": icons.vocabularyIcon,
            "text": "Vocabulary"
        },
        {
            "icon": icons.punctuationIcon,
            "text": "Punctuation"
        },
        {
            "icon": icons.tensesIcon,
            "text": "Tenses"
        },
        {
            "icon": icons.phrasalIcon,
            "text": "Phrasal verbs"
        },
        {
            "icon": icons.languageIcon,
            "text": "Language styles"
        }
    ]
    const targetLanguage = window.sessionStorage.getItem("targetLanguage")

    return (
        <P.PageContainer>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <Header title={ `What aspects of ${ targetLanguage } would you like to improve?` }
                    toolbarPercentage={ "72%" }
                    toolbarText={ "5/7" }/>
            <P.QuestionContainer>
                <MultiSelectOptions options={ langArray } onClick={ onClick }/>
            </P.QuestionContainer>
        </P.PageContainer>
    );
}

