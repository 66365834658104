import englishIcon from "../../assets/v4/drawable-mdpi/ic_english.webp";
import spanishIcon from "../../assets/v4/drawable-mdpi/ic_spanish.webp";
import arabicIcon from "../../assets/v4/drawable-mdpi/ic_arabic.webp";
import chineseIcon from "../../assets/v4/drawable-mdpi/ic_chinese.webp";
import frenchIcon from "../../assets/v4/drawable-mdpi/ic_french.webp";
import germanIcon from "../../assets/v4/drawable-mdpi/ic_german.webp";
import italianIcon from "../../assets/v4/drawable-mdpi/ic_italian.webp";
import koreanIcon from "../../assets/v4/drawable-mdpi/ic_korean.webp";
import ukrainianIcon from "../../assets/v4/drawable-mdpi/ic_ukrainian.webp";
import persianIcon from "../../assets/v4/drawable-mdpi/ic_persian.webp";
import hindiIcon from "../../assets/v4/drawable-mdpi/ic_hindi.webp";
import polishIcon from "../../assets/v4/drawable-mdpi/ic_polish.webp";
import portugueseIcon from "../../assets/v4/drawable-mdpi/ic_portuguese.webp";
import turkishIcon from "../../assets/v4/drawable-mdpi/ic_turkey.webp";
import backArrow from "../../assets/v4/drawable-mdpi/ic_backarrow.webp";
import closeIcon from "../../assets/v4/drawable-mdpi/ic_close_blue.webp";
import logo from "../../assets/v4/drawable-mdpi/ic_logo.webp";
import workIcon from "../../assets/v4/drawable-mdpi/ic_work.webp";
import educationIcon from "../../assets/v4/drawable-mdpi/ic_education.webp";
import cultureIcon from "../../assets/v4/drawable-mdpi/ic_culture.webp";
import brainIcon from "../../assets/v4/drawable-mdpi/ic_brain.webp";
import familyIcon from "../../assets/v4/drawable-mdpi/ic_family.webp";
import travelIcon from "../../assets/v4/drawable-mdpi/ic_travel.webp";
import otherIcon from "../../assets/v4/drawable-mdpi/ic_other.webp";
import bookBrain from "../../assets/v4/drawable-mdpi/ic_bookbrain.webp";
import beginnerIcon from "../../assets/v4/drawable-mdpi/ic_beginner.webp";
import elementaryIcon from "../../assets/v4/drawable-mdpi/ic_elementary.webp";
import intermediateIcon from "../../assets/v4/drawable-mdpi/ic_intermediate.webp";
import advancedIcon from "../../assets/v4/drawable-mdpi/ic_advanced.webp";
import laptop from "../../assets/v4/drawable-mdpi/ic_laptop.webp";
import spellingIcon from "../../assets/v4/drawable-mdpi/ic_spelling.webp";
import vocabularyIcon from "../../assets/v4/drawable-mdpi/ic_vocobulary.webp";
import punctuationIcon from "../../assets/v4/drawable-mdpi/ic_punctuation.webp";
import tensesIcon from "../../assets/v4/drawable-mdpi/ic_tenses.webp";
import phrasalIcon from "../../assets/v4/drawable-mdpi/ic_phrasal.webp";
import languageIcon from "../../assets/v4/drawable-mdpi/ic_langugage.webp";
import min1015Icon from "../../assets/v4/drawable-mdpi/ic_10_15min.webp";
import min2040Icon from "../../assets/v4/drawable-mdpi/ic_ic_20_40min.webp";
import hour1Icon from "../../assets/v4/drawable-mdpi/ic_1hour.webp";
import hourMoreThan1Icon from "../../assets/v4/drawable-mdpi/ic_morethan.webp";
import hourGlass from "../../assets/v4/drawable-mdpi/ic_hourglass.webp";
import oneTime from "../../assets/v4/drawable-mdpi/ic_onetime.webp";
import twoTimes from "../../assets/v4/drawable-mdpi/ic_twotimes.webp";
import threeTimes from "../../assets/v4/drawable-mdpi/ic_threetimes.webp";
import fourTimes from "../../assets/v4/drawable-mdpi/ic_fourtimes.webp";
import fiveTimes from "../../assets/v4/drawable-mdpi/ic_fivetimes.webp";
import star from "../../assets/v4/drawable-mdpi/ic_star.webp";
import star2 from "../../assets/v4/drawable-mdpi/ic_star2.webp";
import rocket from "../../assets/v4/drawable-mdpi/ic_rocket.webp";
import unselected from "../../assets/v4/drawable-mdpi/ic_check_unselected.webp";
import verify from "../../assets/v4/drawable-mdpi/ic_verify.webp";
import selectedIcon from "../../assets/v4/drawable-mdpi/ic_check_selected.webp";
import eyeIcon from "../../assets/v4/drawable-mdpi/ic_eye.webp";
import eyeCloseIcon from "../../assets/v4/drawable-mdpi/ic_eye_close.png";
import paymentSuccess from "../../assets/v4/drawable-mdpi/payment_success.svg";
import paymentFailed from "../../assets/v4/drawable-mdpi/payment_failed.svg";
import warningIcon from "../../assets/v4/drawable-mdpi/ic_warning.svg";
import forecast from "../../assets/v4/forecast.svg";
import visa from "../../assets/v4/visa.png";
import regularpremium from "../../assets/v4/regularpremium.svg";

export default {
    englishIcon,
    spanishIcon,
    arabicIcon,
    chineseIcon,
    frenchIcon,
    germanIcon,
    italianIcon,
    koreanIcon,
    ukrainianIcon,
    persianIcon,
    hindiIcon,
    polishIcon,
    portugueseIcon,
    turkishIcon,
    backArrow,
    closeIcon,
    logo,
    workIcon,
    educationIcon,
    cultureIcon,
    brainIcon,
    familyIcon,
    travelIcon,
    otherIcon,
    bookBrain,
    beginnerIcon,
    elementaryIcon,
    intermediateIcon,
    advancedIcon,
    laptop,
    spellingIcon,
    vocabularyIcon,
    punctuationIcon,
    tensesIcon,
    phrasalIcon,
    languageIcon,
    min1015Icon,
    min2040Icon,
    hour1Icon,
    hourMoreThan1Icon,
    hourGlass,
    oneTime,
    twoTimes,
    threeTimes,
    fourTimes,
    fiveTimes,
    star,
    star2,
    forecast,
    rocket,
    regularpremium,
    unselected,
    visa,
    verify,
    selectedIcon,
    eyeIcon,
    eyeCloseIcon,
    paymentSuccess,
    paymentFailed,
    warningIcon
}
