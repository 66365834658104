import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"
import { InformativeStyles as I } from "./Styles";

import userService, { getUserId } from "../../services/user.service";
import { Informative, InformativeProps } from "./Informative";

import "../../v4.css"

export const WellDoneC4: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();


    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    const informativeProps: InformativeProps = {
        title: "Well done!",
        icon: icons.star,
        desc:
            <I.InformativeDescText>
                Now save your personalized plan by setting up an account
            </I.InformativeDescText>
        ,
        background: "#BEC8FF",
        buttonText: "NEXT",
        onClick: () => {
            onClick("WellDone")
        }
    }

    return (
        <Informative { ...informativeProps } />
    );
}

