import React from 'react';
import icons from "./images"
import { HeaderStyles as H, InformativeStyles as I, PageStyles as P } from "./Styles";
import { Button } from "./Button";

export interface InformativeProps {
    title: string,
    icon: string,
    desc: JSX.Element,
    buttonText: string,
    onClick: any,
    background: string
}

export const Informative = (props: InformativeProps) => {

    return (
        <P.PageContainer>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <I.InformativeContainer background={ props.background }>
                <I.InformativeTitleContainer>
                    <I.InformativeTitle>
                        { props.title }
                    </I.InformativeTitle>
                </I.InformativeTitleContainer>
                <I.InformativeImage img={ props.icon }/>
                <I.InformativeDescContainer>
                    { props.desc }
                </I.InformativeDescContainer>
            </I.InformativeContainer>
            <Button onclick={ props.onClick } text={ props.buttonText }/>
        </P.PageContainer>
    );
}

