import React from 'react';
import { ButtonStyles as B } from "./Styles";
import oval from "../../assets/checkout/oval.svg";

export interface ButtonProps {
    text: string,
    onclick: any,
    disabled?: boolean
}

export interface ProgressButtonProps {
    text: string,
    onclick: any,
    disabled?: boolean,
    loading: boolean
}

export const Button = (params: ButtonProps) => {
    return (
        <B.ButtonContainer onClick={ params.onclick } disabled={ !!params.disabled }>
            <B.ButtonText>
                { params.text }
            </B.ButtonText>
        </B.ButtonContainer>
    );
}

export const ProgressButton = (props: ProgressButtonProps) => {
    return (
        <B.ButtonContainer onClick={ props.onclick } disabled={ !!props.disabled }>
            { props.loading &&
                <B.LoadingContainer>
                    <B.Loading src={ oval }/>
                </B.LoadingContainer>
            }

            { !props.loading &&
                <B.ButtonText>
                    { props.text }
                </B.ButtonText>
            }

        </B.ButtonContainer>
    );
}
