import React, { useEffect, useRef, useState } from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"
import { HeaderStyles as H, PageStyles as P } from "./Styles";
import { AnswersOverview } from "./AnswersOverview";
import { Forecast } from "./Forecast";
import { Plans } from "./Plans";
import { Checkout } from "./Checkout";
import { getUserId } from "../../services/user.service";
import stripeService, { stripePromise } from "../../services/stripe.service";
import { configId } from "../../config";
import { captureException } from "../../services/sentry.service";
import { useGetPageInfo } from "../../hooks/useGetPageInfo";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentSuccess } from "./PaymentSuccess";
import { PaymentFailed } from "./PaymentFailed";
import { firebaseService } from "../../services/firebase.service";
import { fbq } from "../../services/metapixel.service";
import { RetrievePaymentIntent } from "../../api/dto";
import { Recommended } from "./Recommended";
import { LearnFaster } from "./LearnFaster";

import "../../v4.css"

export const PaymentC4: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const [plan, setPlan] = useState()
    const [paymentSuccess, setPaymentSuccess] = useState(false)
    const [paymentFailed, setPaymentFailed] = useState(false)
    const [paymentDisabled, setPaymentDisabled] = useState(false)
    const [paymentMode, setPaymentMode] = useState(false)
    const [loading, setLoading] = useState(false);
    const { i18n, t } = useTranslation();
    const [clientSecret, setClientSecret] = useState("")
    const { currentCohort } = useGetPageInfo()
    const checkoutRef = useRef<null | HTMLDivElement>(null);
    const paymentSuccessRef = useRef<null | HTMLDivElement>(null);
    const paymentFailedRef = useRef<null | HTMLDivElement>(null);
    const plansRef = useRef<null | HTMLDivElement>(null);

    const targetLanguage = window.sessionStorage.getItem("targetLanguage")
    const currentLevel = window.sessionStorage.getItem("currentLevel")
    const motivation = window.sessionStorage.getItem("motivation")
    let motivations: string[] = [];
    if (motivation) {
        motivations = motivation.split(",")
    }

    const aspect = window.sessionStorage.getItem("aspects")
    let aspects: string[] = [];
    if (aspect) {
        aspects = aspect.split(",")
    }

    const answers = [[targetLanguage as string], [currentLevel as string], motivations, aspects]

    const scrollToMyRef = (ref: any) => {
        setTimeout(function () {
            ref.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }, 100);
    };

    const onPlanSelected = async (price: any) => {
        setPaymentMode(true);
        setLoading(true);
        setPlan(price);
        getUserId().then(userId => stripeService.payment({
            configId: configId,
            customerId: undefined,
            priceId: price.id,
            subscriptionId: undefined,
            userId: userId,
            cohortId: currentCohort,
            email: window.sessionStorage.getItem("email"),
            initialUrl: window.sessionStorage.getItem("initialUrl")
        })).then(({ user, subscription, customer }) => {
            setClientSecret(subscription.latest_invoice.payment_intent.client_secret);
            setLoading(false);
            scrollToMyRef(checkoutRef);
        }).catch(err => {
            captureException(err);
            setLoading(false);
        });
    }

    const onPaymentFailed = () => {
        setPaymentFailed(true);
        scrollToMyRef(paymentFailedRef);
        setPaymentDisabled(true);
    }

    const onPaymentSuccess = (paymentIntent: any) => {
        setPaymentMode(true);
        setPaymentSuccess(true);
        scrollToMyRef(paymentSuccessRef);
        setPaymentDisabled(true);
        firebaseService.logFirebaseEvent("checkout_payment_succeeded")
        fbq('trackCustom', 'checkout_payment_succeeded')
        fbq('track', 'Purchase', {
            content_ids: [paymentIntent.id],
            // eventref: 'fb_oea',
            currency: paymentIntent.currency,
            num_items: 1,
            value: paymentIntent.amount / 100.0,
        })
    }

    const onTryAgain = () => {
        setPaymentFailed(false);
        setClientSecret("");
        scrollToMyRef(plansRef);
        setPaymentDisabled(false);
        setPaymentMode(false);
    }

    useEffect(() => {
        const paymentIntentId = new URLSearchParams(window.location.search).get(
            "payment_intent"
        );

        if (!paymentIntentId) {
            return;
        }

        const retrievePaymentIntent: RetrievePaymentIntent = {
            configId: configId,
            id: paymentIntentId
        }


        stripeService.retrievePaymentIntent(retrievePaymentIntent).then(paymentIntent => {
            if (!paymentIntent) {
                return
            }

            switch (paymentIntent.status) {
                case "succeeded":
                    onPaymentSuccess(paymentIntent);
                    break;
                case "processing":
                    const msg = t("payments.alerts.processing")
                    break;
                case "requires_payment_method":
                    onPaymentFailed();
                    break;
                default:
                    onPaymentFailed();
                    break;
            }
        });
    }, []);

    return (
        <P.PageContainer>
            { paymentMode && <P.Overlay/> }
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <AnswersOverview answers={ answers }/>
            { (!paymentMode) &&
                <>
                    <Forecast/>
                    <Recommended/>
                    <LearnFaster/>
                </>
            }

            <div ref={ paymentSuccessRef }>
                { paymentSuccess && <PaymentSuccess/> }
            </div>
            <div ref={ paymentFailedRef }>
                { paymentFailed && <PaymentFailed onTryAgain={ onTryAgain }/> }
            </div>
            <div ref={ checkoutRef }>
                { (paymentMode && clientSecret && !loading) &&
                    <Elements stripe={ stripePromise } options={ { clientSecret: clientSecret } }
                              key={ clientSecret }>
                        <Checkout plan={ plan } onPaymentFailed={ onPaymentFailed } onClose={ () => {
                            setClientSecret("");
                            setPaymentDisabled(false);
                            setPaymentMode(false);
                        } }/>
                    </Elements>
                }
            </div>
            <div ref={ plansRef }>
                <Plans onClick={ onPlanSelected } loading={ loading } disabled={ paymentDisabled }/>
            </div>
        </P.PageContainer>
    );
}

