import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"

import userService, { getUserId } from "../../services/user.service";
import { HeaderStyles as H, PageStyles as P, } from "./Styles";
import Header from "./Header";
import Options from "./Options";
import "../../v4.css"

export const CurrentLevelC4: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        window.sessionStorage.setItem("currentLevel", value);

        handleContinue(value)
    }

    const langArray = [
        {
            "icon": icons.beginnerIcon,
            "text": "Beginner"
        },
        {
            "icon": icons.elementaryIcon,
            "text": "Elementary"
        },
        {
            "icon": icons.intermediateIcon,
            "text": "Intermediate"
        },
        {
            "icon": icons.advancedIcon,
            "text": "Advanced"
        }
    ]

    const targetLanguage = window.sessionStorage.getItem("targetLanguage")

    return (
        <P.PageContainer>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <Header title={ `What is your current ${ targetLanguage } level?` } toolbarPercentage={ "58%" }
                    toolbarText={ "4/7" }/>
            <P.QuestionContainer>
                <Options options={ langArray } onClick={ onClick }/>
            </P.QuestionContainer>
        </P.PageContainer>
    );
}

