import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"
import { InformativeStyles as I } from "./Styles";

import userService, { getUserId } from "../../services/user.service";
import { Informative, InformativeProps } from "./Informative";
import "../../v4.css"

export const AllLevelLessons: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    const informativeProps: InformativeProps = {
        title: "You’ll find lessons for all levels",
        icon: icons.laptop,
        desc:
            <I.InformativeDescText>
                Videos, live classes and more to
                <I.InformativeDescTextBold>
                    &nbsp;help you understand&nbsp;
                </I.InformativeDescTextBold>
                every aspect of
                <I.InformativeDescTextBold>
                    &nbsp;English&nbsp;
                </I.InformativeDescTextBold>
            </I.InformativeDescText>
        ,
        background: "#F5A0D2",
        buttonText: "NEXT",
        onClick: () => {
            onClick("AllLevelLessons")
        }
    }

    return (
        <Informative { ...informativeProps } />
    );
}

