import React from "react";
import { TitleStyles as Ti } from "./Styles";


export interface TitleProps {
    title: string,
}

export default function Title(props: TitleProps) {

    return (
        <Ti.TitleContainer>
            <Ti.TitleText>
                { props.title }
            </Ti.TitleText>
        </Ti.TitleContainer>
    )
}
