import React, { useEffect, useMemo } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import './App.css';
import { useGetPageInfo } from './hooks/useGetPageInfo'
import { getPathFromPageId } from "./helpers/getPathFromPageId";
import { ID_TO_PAGE_MAP } from "./pages/Pages";
import { ProtectedRoute } from "./components/ProtectedRoute/ProtectedRoute";
import { StyledRouteListContainer as S } from "./RouteList.styles";
import Cancel from "./pages/Cancel/Cancel";
import { firebaseService } from "./services/firebase.service";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import SubscriptionTerms from "./pages/SubscriptionTerms/SubscriptionTerms";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import { Statistics } from "./pages/questions/Statistics/Statistics";
import { Discount } from "./pages/questions/Discount/Discount";


export default function RouteList() {

    const { currentVariantPageList, currentCohort, currentPageId } = useGetPageInfo()
    const firstPagePath = useMemo(
        () =>
            getPathFromPageId({
                cohort: currentCohort,
                pageId: currentVariantPageList[0].id,
            }),
        [currentCohort, currentVariantPageList],
    )
    const pageMapId = ID_TO_PAGE_MAP

    useEffect(() => {
        firebaseService?.logFirebaseScreenView("/" + currentCohort + "/" + currentPageId)
    }, [currentPageId])

    return useRoutes([
        { index: true, element: <Navigate to={ firstPagePath }/> },
        {
            element: <Outlet/>, // todo <SubscribeFlow/>
            children: currentVariantPageList.map(({ id: pageId }, index, arr) => {
                const CurrentPage = pageMapId(pageId)

                const nextPagePath = getPathFromPageId({
                    cohort: currentCohort,
                    pageId: arr[index + 1]?.id,
                })
                window.scrollTo(0, 0)

                return {
                    path: `${ currentCohort }/${ pageId }`,
                    element: (
                        <S.Container cohort={ currentCohort }>
                            <ProtectedRoute isAvailable={ true }>
                                <CurrentPage pageId={ pageId } nextPagePath={ nextPagePath }/>
                            </ProtectedRoute>
                        </S.Container>
                    ),
                }
            }),
        },
        { path: '/v3/statistics', element: <Statistics/> },
        { path: '/v3/discount', element: <Discount/> },
        { path: '/cancel', element: <Cancel/> },
        { path: '/tos', element: <TermsOfService/> },
        { path: '/st', element: <SubscriptionTerms/> },
        { path: '/pp', element: <PrivacyPolicy/> },
        { path: '*', element: <Navigate to={ firstPagePath }/> },
    ])
}

